import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MensajesService } from 'src/app/services/mensajes.service';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ParametrosService } from 'src/app/services/parametros.service';

@Component({
  selector: 'app-categorias',
  templateUrl: './categorias.component.html',
  styleUrls: ['./categorias.component.css']
})
export class CategoriasComponent implements OnInit {
  /*Icons*/
  faPencilAlt = faPencilAlt;
  faTrash = faTrash;

  formularioCategoria: FormGroup;
  formularioCantidad: FormGroup;
  categoriaID = "";
  categoriaForm = false;
  editForm = false;
  showParametro: boolean = false;
  arrayCategorias = [];
  currentIndex: any;
  parametros: any = {};

  constructor(private db: AngularFirestore, private formBuilder: FormBuilder, private msj: MensajesService, private params: ParametrosService) { }

  ngOnInit(): void {
    this.loadCategorias();
    this.loadParametros();
    this.formularioCategoria = this.formBuilder.group({ Nombre: ['', Validators.required] });
    this.formularioCantidad = this.formBuilder.group({ Cantidad: ['', Validators.required]});
  }
  public loadCategorias(){
    this.db.collection('Categorias').get().subscribe((categorias) => {
      categorias.docs.forEach(categoria => {
        this.arrayCategorias.push({
          data: categoria.data(),
          id: categoria.id
        })
      })
    })

  }
  public loadParametros(){
    this.params.getParametros().subscribe(parameters => {
      this.parametros = parameters.docs[0].data();
      
      this.formularioCantidad.patchValue({
        Cantidad: this.parametros.ProductosCantidad
      })
    })
  }
  public showParametros(){
    this.showParametro = true;
  }
  public editCantidad(){
    this.params.updateParametros({ProductosCantidad: this.formularioCantidad.value.Cantidad})
    .then(result => {
      this.msj.mensajeCorrecto('Correcto', `Max. Productos = ${this.formularioCantidad.value.Cantidad}`)
    })
  }
  public createCategoria(){
    this.categoriaForm = true;
  }
  public onEdit(index){
    this.currentIndex = index;
    this.categoriaForm = true;
    this.editForm = true;
    this.formularioCategoria.patchValue({
      Nombre: this.arrayCategorias[this.currentIndex].data.name
    })
  }

  public editCategoria(){
    this.arrayCategorias[this.currentIndex].data.name = this.formularioCategoria.value.Nombre;
    // let categorias = {Categorias: this.arrayCategorias};
    this.db.collection('Categorias').doc(this.arrayCategorias[this.currentIndex].id).update(this.arrayCategorias[this.currentIndex].data)
    .then(result => {
      this.msj.mensajeCorrecto('Correcto', 'Categoria actualizada');
      this.categoriaForm = false;
    })
    .catch(err => {})
  }
  public onDelete(index){
    this.currentIndex = index;
  }

  public deleteCategoria(){
    this.arrayCategorias.splice(this.currentIndex, 1);
    let categorias = {Categorias: this.arrayCategorias};
    this.db.collection('Parametros').doc(this.categoriaID).update(categorias)
    .then(result => {
      this.msj.mensajeCorrecto('Correcto', 'Categoria eliminada');
    })
    .catch(err => {})

  }
  public addCategoria(){
    this.arrayCategorias.push(this.formularioCategoria.value.Nombre);
    let categorias = {Categorias: this.arrayCategorias};
    this.db.collection('Parametros').doc(this.categoriaID).update(categorias)
    .then(result => {
      this.msj.mensajeCorrecto('Correcto', 'Categoria agregada');
      this.formularioCategoria.reset();
      this.categoriaForm = false;
    })
    .catch(err => {})
    
  }

  public closeCategoriaForm(){
    this.categoriaForm = false;
    this.showParametro = false;
  }

}
