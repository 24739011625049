// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase:{
    apiKey: "AIzaSyAwQMNzXX_aGhQSA7sxeGAcryAMjcLcaUU",
    authDomain: "orlondonol-2e688.firebaseapp.com",
    databaseURL: "https://orlondonol-2e688.firebaseio.com",
    projectId: "orlondonol-2e688",
    storageBucket: "orlondonol-2e688.appspot.com",
    messagingSenderId: "1069523152214",
    appId: "1:1069523152214:web:65ab2f568270221b673af7",
    measurementId: "G-FLZ92K5TH3"
  }
};

if(location.hostname == 'localhost'){
  environment.production = false;
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
