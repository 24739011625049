import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { MensajesService } from './mensajes.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticateService {

  public currentUser = null;
  public currentRole = null;
  public displayName = null;
  
  constructor(public auth: AngularFireAuth,private db: AngularFirestore,private router: Router,private mensajesService:MensajesService) { 
  }

  logout() {
    this.auth.signOut();
    this.currentUser = null;
    this.currentRole = null;
    sessionStorage.setItem('User',this.currentUser);
    sessionStorage.setItem('Role',this.currentRole);
    sessionStorage.setItem('DisplayName',this.displayName);
    this.router.navigate(['login']);
        
  }

  login(username,password)
  {
     this.auth.signInWithEmailAndPassword(username, password).then((user) => {       
        this.currentUser=user;
        sessionStorage.setItem('User',this.currentUser.user.email);
        sessionStorage.setItem('DisplayName',this.currentUser.user.email);        
        this.setRole(user);
      }).catch((error) => {

        switch (error.message){
          case "The password is invalid or the user does not have a password.":
            this.mensajesService.mensajeAdvertencia("Advertencia","La contraseña es invalidad o el usuario no existe, Intente de nuevo.");           
            break;
          case "Too many unsuccessful login attempts. Please try again later.":
            this.mensajesService.mensajeAdvertencia("Advertencia","Tiene muchos intentos fallidos, por favor intente mas tarde, si no recuerda su contraseña contacte al administrador.");           
            break;
          default:
            this.mensajesService.mensajeAdvertencia("Advertencia",error.message);  
            break;         
        }
        this.currentUser = null;
        this.currentRole = null;
        this.displayName = null;
        sessionStorage.setItem('User',this.currentUser);
        sessionStorage.setItem('Role',this.currentRole);
        sessionStorage.setItem('DisplayName',this.currentRole);
      }) 
  }

  setRole(user){    
    this.db.collection('Administradores').get().subscribe((admins) => {
      let isAdmin=false;
      for (let index = 0; index < admins.docs.length; index++) {
        const data = admins.docs[index].data();
        if(data.email==user.user.email){
          isAdmin=true;
          this.currentRole= 'Admin';
          this.router.navigate(['expositores']);     
        }
      }
      if(!isAdmin)
      {
        this.currentRole='Expositor';
      }
      sessionStorage.setItem('Role',this.currentRole);
      if(this.currentRole=="Expositor")
      {   
        this.router.navigate(['expositor']);
      }
    });
  }

  createUserAuth(email, password){
    return this.auth.createUserWithEmailAndPassword(email, password);
  }

  forgetPassword(email){
    return this.auth.sendPasswordResetEmail(email);
  }
}
