import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticateService } from 'src/app/services/authenticate.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {
  displayName:string;
  email:string;
  rol:string;

  public get currentRol() {
    return this.authService.currentRole;
  }

  public get currentUser() {
    return this.authService.currentUser;
  }

  constructor(private authService: AuthenticateService,private router: Router,private db: AngularFirestore) { 
      if(this.currentUser)
      {
        this.email=this.currentUser.user.email;
        this.rol=this.currentRol;
        if(this.rol=="Expositor")
        {
          this.db.collection('Expositores',ref => ref.where('Email', '==', this.email)).get().subscribe((expositores) => {
            expositores.docs.forEach((item) => {
                const expositor = item.data();
                this.displayName=expositor.Nombres +" "+expositor.Apellidos;
            });
          });
        }
        else
        {
          this.displayName=this.email;
        }
      }
  }  

  ngOnInit() {
  
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}
