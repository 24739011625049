import { Component } from '@angular/core';
import { AuthenticateService } from './services/authenticate.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Sistema de Información del Mercado de las Pulgas';
  constructor(private authService: AuthenticateService,private router: Router,private route: ActivatedRoute) 
  {
    
  }

  ngOnInit() {
    if(this.authService.currentUser)
    {
        this.router.navigate(['home']);      
    }
    else
    {
      
      
      if(window.location.href.indexOf("expositoreswebpage")<0)
      {
        this.router.navigate(['login']);
      }
    }
  }
}