import { Routes, RouterModule} from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { ExpositoresComponent } from './components/expositores/expositores.component';
import { StandsComponent } from './components/stands/stands.component';
import { HomeComponent } from './components/home/home.component';
import { ProductosComponent } from './components/productos/productos.component';
import { BannerComponent } from './components/banner/banner.component';
import { ServiciosComponent } from './components/servicios/servicios.component';
import { ExpositoresServiciosComponent } from './components/expositoresservicios/expositoresservicios.component';
import { PagosComponent } from './components/pagos/pagos.component';
import { MapaComponent } from './components/mapa/mapa.component';
import { ExpositorComponent } from './components/expositor/expositor.component';
import { CarguemasivoComponent } from './components/carguemasivo/carguemasivo.component';
import { ExpositoreswebpageComponent } from './components/expositoreswebpage/expositoreswebpage.component';
import { OrdenesComponent } from './components/ordenes/ordenes.component';
import { AdministradorComponent } from './components/administrador/administrador.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component'
import { CategoriasComponent } from './components/categorias/categorias.component';
import { ClientesComponent } from './components/clientes/clientes.component';
import { ReportesComponent } from './components/reportes/reportes.component';
const app_routes: Routes = [
    {path: 'login', component: LoginComponent},
    {path: 'categorias', component: CategoriasComponent},
    {path: 'recuperar-contrasena', component: ForgotPasswordComponent},
    {path: 'administrador', component: AdministradorComponent},
    {path: 'expositor', component: ExpositorComponent},
    {path: 'expositor/:expositorID', component: ExpositorComponent},
    {path: 'expositores', component: ExpositoresComponent},
    {path: 'clientes', component: ClientesComponent},
    {path: 'expositores/:expositorID', component: ExpositoresComponent},
    {path: 'stands', component: StandsComponent},
    {path: 'home', component: HomeComponent},
    {path: 'banner', component:BannerComponent},
    {path: 'ordenes', component:OrdenesComponent},
    {path: 'reportes', component: ReportesComponent},
    {path: 'productos', component:ProductosComponent},
    {path: 'productos/:expositorID', component: ProductosComponent},
    {path: 'servicios', component:ServiciosComponent},
    {path: 'servicios/:servicioID', component: ServiciosComponent},
    {path: 'expositoresservicios', component:ExpositoresServiciosComponent},
    {path: 'expositoresservicios/:expositoresservicioID', component: ExpositoresServiciosComponent},
    {path: 'carguemasivo', component:CarguemasivoComponent},
    {path: 'pagos', component:PagosComponent},
    {path: 'mapa', component:MapaComponent},
    {path: 'expositoreswebpage', component:ExpositoreswebpageComponent},  
    {path: '', component: HomeComponent},
    {path: '**', pathMatch: 'full', redirectTo: ''}
];

export const app_routing = RouterModule.forRoot(app_routes);