import { Component, OnInit } from '@angular/core';
import { AuthenticateService } from 'src/app/services/authenticate.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  
  public get currentRol() {
    return this.authService.currentRole;
  }

  public get currentUser() {
    return this.authService.currentUser;
  }
  
  constructor(private authService: AuthenticateService) { 

  }
  
  ngOnInit() {
    
    
  }
}
